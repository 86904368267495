<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="a" x1="0.763" x2="0.259" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g transform="translate(-370 -688)">
    <rect :fill="background" height="60" transform="translate(370 688)" width="60"/>
    <path d="M21.725,21.725a8.613,8.613,0,1,0-8.613-8.613A8.613,8.613,0,0,0,21.725,21.725Zm0,4.306C16.02,26.032,4.5,28.939,4.5,34.645v4.306H38.951V34.645c0-5.706-11.52-8.613-17.226-8.613Z" fill="url(#a)" transform="translate(378.274 696.274)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>